import request from '@/utils/request'

/**
 * 意向修改审核
 */
export class StageAudit {
  // 意向修改审核列表
  static index (params) {
    return request({
      url: '/stageAudit/index',
      method: 'get',
      params
    })
  }
  // 意向修改审核
  static audit (params) {
    return request({
      url: '/stageAudit/audit',
      method: 'PUT',
      params
    })
  }

  static batchAudit (params) {
    return request({
      url: '/stageAudit/batchAudit',
      method: 'PUT',
      params
    })
  }
}