<template>
  <div class="index">
    <van-nav-bar
      title="客户意向审核"
      left-text="返回"
      left-arrow
      fixed
      right-text="筛选"
      :placeholder="true"
      @click-left="onClickLeft"
       @click-right="onClickRight"
    />
    <div class="content">
      <div class="batchOperation">
        <span>共{{ total }}条</span>
        <span @click="batchOperation = true">批量操作</span>
      </div> 
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="list.length? '没有更多了' : ''"
        @load="onLoad"
        :style="{paddingBottom: batchOperation ? '60px' : ''}"
      >
        <van-checkbox-group v-model="batchOperationArr" @change="batchOperationChange">
        <div class="list-item" v-for="(item, index) in list" :key="index">
          <van-checkbox :name="item.id" v-if="batchOperation && item.audit == 1"></van-checkbox>
          <van-tag v-if="item.audit == 2" type="success">已通过</van-tag>
          <van-tag v-if="item.audit == 3" type="danger">已驳回</van-tag>
          <van-tag v-if="item.audit == 1" type="warning">待审核</van-tag>
          <div style="flex: 1">
            <div class="list-item-content">
              <div>销售：{{ item.employee.name }}</div>
            </div>
            <div class="list-item-content">
              <div>客户：{{ item.contact.name }}</div>
            </div>
            <div class="list-item-content">
              <div>申请时间：{{ item.created_at }}</div>
            </div>
            <div class="list-item-content" v-if="item.audit_time">
              <div>审核时间：{{ item.audit_time }}</div>
            </div>
            <div class="list-item-content">
              <div v-if="item.stage">更改等级：{{ item.stage.name }} <span style="color: #999" v-if="item.current_stage">（原等级{{ item.current_stage.name }}）</span></div>
            </div>
            
            <div class="list-item-content">
              <div>申请原因：{{ item.reason }}</div>
            </div>
            <div class="list-item-content hand">
              <div v-if="item.audit == 1" style="text-align: left"><van-button size="mini" @click="audit(item, 3)">驳 回</van-button></div>
              <div><van-button size="mini" @click="pushUserEmployee(item.contact_id, item.employee_id)">查看客户</van-button></div>
              <div v-if="item.audit == 1" style="text-align: right"><van-button type="info" size="mini" @click="audit(item, 2)">通 过</van-button></div>
            </div>
          </div>
          
        </div>
        </van-checkbox-group>
      </van-list>
      <van-empty
        v-if="finished && !list.length"
        class="custom-image"
        description="暂无数据"
      />
    </div>

    <div class="batchOperationFooter" v-if="batchOperation">
      <span>已选{{ batchOperationArr.length }}条 <a-button type="link" @click="cancelBatch">取消</a-button></span>
      <div>
        <van-button type="danger" size="small" style="margin-right: 16px" @click="batchSubmit('3')">驳 回</van-button>
        <van-button type="primary" size="small" @click="batchSubmit('2')">通 过</van-button>
      </div>
    </div>

    <van-popup v-model="showRight" position="right" :style="{ height: '100%', width: '80%' }">
      <div class="screen">
        <div class="list-item">
          <div class="item-title">
            成员
          </div>
          <div class="item-content" @click="$refs.employee.$show(standbyStaffList,'max')">
            {{ standbyStaffList.length ? standbyStaffList[0].name : '点击选择' }}
            <!-- <van-icon name="close" v-show="standbyStaffList.length > 0"  @click.stop="standbyStaffList = []"/> -->
          </div>
        </div>
        <div class="list-item">
          <div class="item-title">
            部门
          </div>
          <div class="item-content" @click="$refs.department.$show(setDepartment,'max')">
            {{ setDepartment[0] ? setDepartment[0].name : '点击选择' }}
            <!-- <van-icon name="close" v-show="setDepartment.length > 0"  @click.stop="setDepartment = []"/> -->
          </div>
        </div>
        <div class="list-item">
          <div class="item-title">
            状态
          </div>
          <div>
            <div v-for="(item,index) in screenType" :key="index" @click="activeq(item.value)" :class="{qd: item.value === screenAudit}" class="item-content item-lable">
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="list-item">
          <div class="item-title">
            申请时间
          </div>
          <div class="item-content">
            <div @click="showTime = true" style="flex: 1;text-align: center">{{ startDayStr ? startDayStr : '开始时间' }}</div>
            -
            <div @click="showTime = true" style="flex: 1;text-align: center">{{ endDayStr ? endDayStr : '结束时间' }}</div>
            <van-icon name="close" v-show="startDayStr || endDayStr" @click="clearTime('created')"/>
          </div>
        </div>
        <div class="list-item" v-if="screenAudit > 1">
          <div class="item-title">
            审核时间
          </div>
          <div class="item-content">
            <div @click="showAuditTime = true" style="flex: 1;text-align: center">{{ auditStartDayStr ? auditStartDayStr : '开始时间' }}</div>
            -
            <div @click="showAuditTime = true" style="flex: 1;text-align: center">{{ auditEndDayStr ? auditEndDayStr : '结束时间' }}</div>
            <van-icon name="close" v-show="auditStartDayStr || auditEndDayStr" @click="clearTime('audit')"/>
          </div>
        </div>
        <div class="">
          <van-button style="width: 100%" type="info" @click="onSearch">筛选</van-button>
        </div>
      </div>
    </van-popup>
    <van-action-sheet v-model="showTime" :close-on-click-overlay="false">
      <van-datetime-picker
        v-model="currentDate.startDay"
        type="date"
        title="开始日期"
        :formatter="formatter"
        :visible-item-count="4"
      >
        <div slot="confirm"></div>
        <div slot="cancel"></div>
      </van-datetime-picker>
      <van-datetime-picker
        v-model="currentDate.endDay"
        type="date"
        title="结束日期"
        :formatter="formatter"
        :visible-item-count="4"
      >
        <div slot="confirm"></div>
        <div slot="cancel"></div>
      </van-datetime-picker>
      <div class="showSelectDayBtn">
        <van-button @click="showTime = false">取消</van-button>
        <van-button type="info" @click="setDate('created')">确认</van-button>
      </div>
    </van-action-sheet>
    <van-action-sheet v-model="showAuditTime" :close-on-click-overlay="false">
      <van-datetime-picker
        v-model="currentDate.auditStartDay"
        type="date"
        title="开始日期"
        :formatter="formatter"
        :visible-item-count="4"
      >
        <div slot="confirm"></div>
        <div slot="cancel"></div>
      </van-datetime-picker>
      <van-datetime-picker
        v-model="currentDate.auditEndDay"
        type="date"
        title="结束日期"
        :formatter="formatter"
        :visible-item-count="4"
      >
        <div slot="confirm"></div>
        <div slot="cancel"></div>
      </van-datetime-picker>
      <div class="showSelectDayBtn">
        <van-button @click="showAuditTime = false">取消</van-button>
        <van-button type="info" @click="setDate('audit')">确认</van-button>
      </div>
    </van-action-sheet>
    <employeeIndex ref="employee" @change="userChange"/>
    <departmentSelect ref="department" @change="departmentChange"/>
  </div>
</template>
<script>
import { Toast, Dialog, Checkbox, CheckboxGroup } from 'vant'
import { getCookie, pushUserEmployee } from '@/plugins/utils'
import { StageAudit } from '@/api/stageAudit'
import employeeIndex from "../channelCode/components/employeeIndex"
import departmentSelect from "@/components/departmentSelect"

export default {
  components: {
    employeeIndex,
    departmentSelect
  },
  data () {
    return {
      pushUserEmployee,
      loading: false,
      finished: false,
      list: [],
      page: 1,
      perPage: 15,
      total: 0,
      batchOperation: false,
      batchOperationArr: [],

      showRight: false,
      standbyStaffList: [],
      setDepartment: [],
      showTime: false,
      showAuditTime: false,
      currentDate: {
        startDay: new Date(),
        endDay: new Date(),
        auditStartDay: new Date(),
        auditEndDay: new Date(),
      },
      screenAudit: -1,
      startDayStr: '',
      endDayStr: '',
      auditStartDayStr: '',
      auditEndDayStr: '',
      showPicker: false,

      screenType: [
        {
          name: '全部',
          value: -1
        },
        {
          name: '不需审核',
          value: 0
        },
        {
          name: '待审核',
          value: 1
        },
        {
          name: '已通过',
          value: 2
        },
        {
          name: '已驳回',
          value: 3
        }
      ]
    }
  },
  created () {
    window.scrollTo(0, 0)
  },
  methods: {
    batchOperationChange (v) {
      console.log(this.batchOperationArr)
    },
    cancelBatch () {
      this.batchOperationArr = []
      this.batchOperation = false
    },
    batchSubmit (type) {
      if (this.batchOperationArr.length) {
        let text = ''
        if (type == 2) {
          text = '通过'
        }else if (type == 3){
          text = '驳回'
        }
        Dialog.confirm({
          message: '确定要批量' + text + this.batchOperationArr.length + '条申请？',
          confirmButtonColor: '#1890ff'
        })
        .then(() => {
          StageAudit.batchAudit({
            audit: type,
            ids: this.batchOperationArr
          }).then(res => {
            this.batchOperation = false
            this.batchOperationArr = []
            this.finished = false
            this.page = 1
            this.list = []
            this.onLoad()
            Toast.success('操作成功')
          })
        })
      }
      
    },
    onClickLeft() {
      this.$router.back()
    },
    onLoad () {
      this.loading = true
      let param = {
        page: this.page,
        page_size: this.perPage,
      }
      if (this.screenAudit != -1) param.audit = this.screenAudit
      if (this.standbyStaffList.length && this.standbyStaffList[0]) param.employee_id = this.standbyStaffList[0].id
      if (this.setDepartment.length && this.setDepartment[0]) param.department_id = this.setDepartment[0].id
      if (this.startDayStr) param.created_at_mt = this.startDayStr + ' 00:00'
      if (this.endDayStr) param.created_at_lt = this.endDayStr + ' 23:59'
      if (this.auditStartDayStr) param.audit_time_mt = this.auditStartDayStr + ' 00:00'
      if (this.auditEndDayStr) param.audit_time_lt = this.auditEndDayStr + ' 23:59'
      StageAudit.index(param).then(res => {
        this.loading = false
        if(res.data.lists.data.length < this.perPage){
          this.finished = true
          this.loading = false
        }else{
          this.page++
          this.finished = false
          this.loading = false
        }
        this.total = res.data.lists.total
        this.list = this.list.concat(res.data.lists.data || [])
      })
    },
    audit (item, type) {
      let text = ''
      if (type == 2) {
        text = '通过'
      }else if (type == 3){
        text = '驳回'
      }
      Dialog.confirm({
        message: '确定要' + text + '该申请？',
        confirmButtonColor: '#1890ff'
      })
      .then(() => {
        // on confirm
        StageAudit.audit({
          id: item.id,
          audit: type
        }).then(res => {
          Toast(text+'成功！')
          this.finished = false
          this.page = 1
          this.list = []
          this.onLoad()
        })
      })
    },
    onSearch () {
      this.list = []
      this.finished = false
      this.page = 1
      this.total - 0
      this.onLoad()
      this.showRight = false
    },
    onClickRight() {
      this.showRight = true
    },
    userChange(data, info) {
      this.standbyStaffList = data
      // this.StandbyStaff = data.map(i=>i.name)
    },
    setDate (type) {
      switch (type) {
        case 'created':
          this.startDayStr = this.dateFormatStr(this.currentDate.startDay)
          this.endDayStr = this.dateFormatStr(this.currentDate.endDay)
          this.showTime = false
          break
        case 'audit':
          this.auditStartDayStr = this.dateFormatStr(this.currentDate.auditStartDay)
          this.auditEndDayStr = this.dateFormatStr(this.currentDate.auditEndDay)
          this.showAuditTime = false
          break
      }
     
    },
    departmentChange (v) {
      this.setDepartment = v
    },
    dateFormatStr (time) {
      var Y = time.getFullYear() + '-';
      var M = (time.getMonth() + 1 < 10 ? '0' + (time.getMonth() + 1) : time.getMonth() + 1) + '-';
      var D = (time.getDate() < 10 ? '0' + time.getDate() : time.getDate());
      return Y + M + D
    },
    clearTime (type) {
      switch (type) {
        case 'created':
          this.startDayStr = ''
          this.endDayStr = ''
          break
        case 'audit':
          this.auditStartDayStr = ''
          this.auditEndDayStr = ''
          break
      }
    },
    formatter(type, val) {
      if (type === 'year') {
        return val + '年';
      }
      if (type === 'month') {
        return val + '月';
      }
      if (type === 'day') {
        return val + '日';
      }
      return val;
    },
    activeq (v) {
      this.screenAudit = v
    }
  }
}
</script>
<style scoped lang="less">
.index {
  font-size: 14px;
  height: 100%;
  background: #f4f5f7;

  /deep/ .van-nav-bar__content {
    background: #2b63cd;
    color: #fff;
  }

  /deep/ .van-nav-bar .van-icon {
    color: #fff;
  }

  /deep/ .van-nav-bar__text {
    color: #fff;
  }

  /deep/ .van-nav-bar__title {
    color: #fff;
  }

  .batchOperationFooter {
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    bottom: 0;
    left: 0;
    right: 0;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: calc(10px + constant(safe-area-inset-bottom));
    padding-bottom: calc(10px + env(safe-area-inset-bottom));
    padding-top: 10px;
    height: 50px;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, .1);
  }

  .content {
    // background: #f4f5f7;
    padding: 12px 12px 16px;

    .batchOperation {
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;
    }

    .list-item {
      margin-bottom: 12px;
      border: 1px solid transparent;
      padding: 12px 12px;
      background-color: #fff;
      border-radius: 6px;
      font-size: 14px;
      color: #585757;
      position: relative;
      display: flex;

      >.van-checkbox {
        margin-right: 20px;
      }

      >.van-tag {
        position: absolute;
        right: 12px;
        top: 20px;
      }

      &-content {
        display: flex;
        margin-bottom: 6px;

        >div {
          flex: 1;
        }
      }

      .hand {
        border-top: 1px solid #f4f5f7;
        text-align: center;
        margin-bottom: 0;
        margin-top: 10px;
        padding-top: 12px;

        button {
          width: 80%;
          font-size: 14px;
        }
      }
      // box-shadow: 2px 0px 6px rgba(0, 0, 0, .05);

      // table {
      //   width: 100%;

      //   th {
      //     width: 100px;
      //   }
      // }
    }
      
  }

  .screen {
    height: 100%;
    padding: 12px;
    background-color: #f4f5f7;

    .list-item {
      margin-bottom: 20px;
    }

    .item-title {
      font-weight: bold;
    }

    .item-content {
      width: 100%;
      background-color: #fff;
      /* background: red; */
      border: 1px solid #E5E6E7;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px;
      border-radius: 7px;
      margin-top: 10px;
    }

    .item-lable {
      display: inline-block;
      width: auto;
      align-items: center;
      line-height: 36px;
      margin: 10px 3px;
      border-radius: 0;
    }

    .qd{
      color: #1890ff!important;
      border-color: #1890ff!important;
      background-color: #f3f9ff!important;
    }
    
  }

  .van-picker {
    margin-bottom: 20px;
  }

  .showSelectDayBtn {
    padding: 10px 5px;
    display: flex;

    button {
      flex: 1;
      margin:  0 5px;
    }
  }
}
</style>
